<template>
  <div class="mobile-about-us-form">
    <v-form ref="form">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.translations.menu_about_us"
            :label="$trans('mobile_about_us_form_menu_about_us_label')"
            outlined
            hide-details
            maxlength="15"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row v-if="$config('company_address')" no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.translations.about_us_location_title"
            :label="$trans('mobile_about_us_form_location_title')"
            outlined
            hide-details
            maxlength="30"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <calendesk-information-message>
            <span v-if="$config('company_address')">{{
              $trans("mobile_about_us_form_location_info")
            }}</span>
            <span v-else>{{
              $trans("mobile_about_us_form_location_2_info")
            }}</span>
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.translations.about_us_about_us_title"
            :label="
              $trans('mobile_about_us_form_about_us_about_us_title_label')
            "
            outlined
            hide-details
            maxlength="30"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mobile-about-us-form__logo">
        <v-col cols="12">
          <calendesk-image-select
            v-model="form.settings.tenants_mobile_image_about_us"
            outline
            :select-type="selectType.MOBILE_ABOUT_US_IMAGE"
            :label="$trans('mobile_about_us_form_logo_label')"
            :select-image-button-text="
              $trans('mobile_about_us_form_logo_select_button')
            "
            :display-remove-image-button="false"
            @input="updateConfigurationInput"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-textarea
            v-model="form.translations.about_us_text"
            outlined
            rows="9"
            :label="$trans('mobile_about_us_form_about_us_text_label')"
            hide-details
            maxlength="1500"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.translations.about_us_employees_title"
            :label="
              $trans('mobile_about_us_form_about_us_employees_title_label')
            "
            outlined
            hide-details
            maxlength="30"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <calendesk-information-message>
            {{ $trans("mobile_about_us_form_employee_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.translations.about_us_contact_title"
            :label="$trans('mobile_about_us_form_about_us_contact_title_label')"
            outlined
            hide-details
            maxlength="30"
            @input="updateTranslationInput"
            @blur="changeTranslationsBlur"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <calendesk-information-message>
            {{ $trans("mobile_about_us_form_contact_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.settings.facebook"
            :label="$trans('profile_company_social_media_facebook_label_input')"
            placeholder="https://facebook.com/"
            outlined
            validate-on-blur
            :rules="
              form.settings.facebook ? [rules.url, rules.maxChars(255)] : []
            "
            @blur="validateAndUpdateConfiguration"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.settings.twitter"
            :label="$trans('profile_company_social_media_twitter_label_input')"
            placeholder="https://twitter.com/"
            outlined
            validate-on-blur
            :rules="
              form.settings.twitter ? [rules.url, rules.maxChars(255)] : []
            "
            @blur="validateAndUpdateConfiguration"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.settings.instagram"
            :label="
              $trans('profile_company_social_media_instagram_label_input')
            "
            placeholder="https://instagram.com/"
            outlined
            validate-on-blur
            :rules="
              form.settings.instagram ? [rules.url, rules.maxChars(255)] : []
            "
            @blur="validateAndUpdateConfiguration"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.settings.youtube"
            :label="$trans('profile_company_social_media_youtube_label_input')"
            placeholder="https://youtube.com/"
            outlined
            validate-on-blurs
            :rules="
              form.settings.youtube ? [rules.url, rules.maxChars(255)] : []
            "
            @blur="validateAndUpdateConfiguration"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CalendeskImageSelect from "@/lib/calendesk-js-library/components/CalendeskImageSelect";
import selectType from "@/components/assets/selectType";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import { maxChars, url } from "@/lib/calendesk-js-library/forms/validators";

export default {
  name: "MobileAboutUsForm",
  components: {
    CalendeskImageSelect,
    CalendeskInformationMessage,
  },
  data() {
    return {
      form: {
        settings: {
          tenants_mobile_image_about_us: {},
          facebook: null,
          twitter: null,
          instagram: null,
          youtube: null,
        },
        translations: {
          menu_about_us: "",
          about_us_about_us_title: "",
          about_us_text: "",
          about_us_location_title: "",
          about_us_employees_title: "",
          about_us_contact_title: "",
        },
      },
      selectType,
      rules: {
        maxChars,
        url,
      },
    };
  },
  computed: {
    ...mapGetters({
      getConfiguration: "mobileEditor/getConfiguration",
      getTranslations: "mobileEditor/getTranslations",
    }),
  },
  watch: {
    getConfiguration() {
      this.updateFields();
    },
    getTranslations() {
      this.updateFields();
    },
  },
  beforeMount() {
    this.updateFields();
  },
  methods: {
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
      updateTranslation: "mobileEditor/updateTranslation",
    }),
    updateFields() {
      if (!this.getConfiguration || !this.getTranslations) return;
      this.form.settings.tenants_mobile_image_about_us =
        this.getConfiguration.tenants_mobile_image_about_us;
      this.form.settings.facebook = this.getConfiguration.facebook;
      this.form.settings.instagram = this.getConfiguration.instagram;
      this.form.settings.youtube = this.getConfiguration.youtube;
      this.form.settings.twitter = this.getConfiguration.twitter;

      this.form.translations.menu_about_us = this.getTranslations.menu_about_us;
      this.form.translations.about_us_location_title =
        this.getTranslations.about_us_location_title;
      this.form.translations.about_us_about_us_title =
        this.getTranslations.about_us_about_us_title;
      this.form.translations.about_us_employees_title =
        this.getTranslations.about_us_employees_title;
      this.form.translations.about_us_contact_title =
        this.getTranslations.about_us_contact_title;
      this.form.translations.about_us_text = this.getTranslations.about_us_text;
    },
    updateTranslationInput() {
      this.updateTranslation({ translations: { ...this.form.translations } });
    },
    updateConfigurationInput() {
      this.changeConfiguration({ settings: { ...this.form.settings } });
    },
    validateAndUpdateConfiguration() {
      if (this.$refs.form.validate()) {
        this.updateConfigurationInput();
      }
    },
    changeTranslationsBlur() {
      this.changeConfiguration({ translations: { ...this.form.translations } });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-about-us-form .row {
  margin-bottom: 2rem;
}
</style>
